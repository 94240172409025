<template>
    <div class="header" :class="[{'h140': type == 'expert'}]">
        <div class="container flex x-left y-center nav">
            <img v-if="type != 2" class="logo" style="height:60px;" :src="companyLogo || require('@/assets/' + logo)" alt="logo">

            <!-- type == 2 机构号logo -->
            <template v-if="type == 2">
                <img class="committee-logo" style="height:60px;" :src="orgContent.index_logo | urlFilter(250, 'avatar') ||  require('@/assets/images/committee-logo.jpg')" alt="logo">
                <div class="ml10 fs18 t-l mr20 committee-logo" style="max-width:200px;">{{ orgContent.name }}</div>
            </template>
            <div class="mobile-login flex y-center flex-item" :class="[type == 'expert' ? 'x-right' : 'x-left' ]">
                <div v-if="type != 'expert'" class="nav-box flex flex-item x-around">
                    <template v-for="(item, index) in navList[0]">
                        <a-dropdown :key="index" v-if="item.children && item.children.length">
                            <a :class="['nav-item','flex','y-center', 'color333', 'fs18', { 'active': index == navCurrentId }]" @click="e => e.preventDefault()">
                                <span>{{item.name}}</span><a-icon style="font-size:16px;margin-left:3px;" type="down" />
                            </a>
                            <a-menu slot="overlay">
                                <a-menu-item v-for="(it,idx) in item.children" :key="idx">
                                    <router-link :to="{ path: it.path }">
                                        {{ it.name }}
                                    </router-link>
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                        <router-link v-else :to="{ path: item.path }"
                            :class="['nav-item', 'color333', 'fs18', { 'active': index == navCurrentId }]"
                            :key="index">
                            {{ item.name }}
                        </router-link>
                    </template>    
                   
                </div>
                <div class="search-header" :class="[!(userInfo || {}).user_id ? 'unlogin-header':'login-header']" v-if="$slots.search">
                    <slot name="search"></slot>
                </div>
                <div v-else class="search-header flex x-left" :class="[!(userInfo || {}).user_id ? 'unlogin-header':'login-header']">
                    <a-input-search allowClear class="search" :class="{'search100':!typeList.length}" v-model="search_name" enter-button placeholder="请输入关键词"
                        @change="handleChange" @search="handleSearch">
                        <a-select allowClear v-if="typeList.length" slot="addonBefore" v-model="currSelected"
                            placeholder="类型" style="width:75px" :style="searchStyle" @change="handleChangeType">
                            <a-select-option :selected="item.selected" v-for="(item, index) in typeList"
                                :value="item.path" :key="index">
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-input-search>
                    
                    <div v-if="type == 3" class="ml10 flex flex-column x-center" style="height:38px;width:100px;">
                        <router-link class="advanced-search fs14"
                            :to="{ path: '/database/advanced-search' }">高级搜索</router-link>
                        <!-- <router-link class="fs14 color000" :to="{ path: '/periodical-list'}">期刊检索</router-link> -->
                    </div>
                </div>
                <!-- type==3 摘要数据库 -->
                <router-link v-if="type == 3" :to="{ path: '/' }" class="return-index t-c fs14 ml25">返回首页</router-link>
                <!-- <search v-else :placeholder="placeHolderText"></search> -->
                <a class="vip-btn ml40" v-if="!(userInfo || {}).is_member" target="_blank" :href="`${personal_url}select-reg-type/${committee_id}`">申请<p>VIP</p> 会员</a>
                <div class="login-btn ml10" v-if="!(userInfo || {}).user_id">
                    <a class="color333 fs14" href="javascript:;" @click="handleLoginEvent">登录</a>
                    <span class="pl5 pr5">|</span>
                    <a class="color333 fs14" href="javascript:;" @click="handleRegisterEvent">注册</a>
                </div>
                <div class="ml10 login-btn login-btn-new" v-else>
                    <a-dropdown placement="bottomCenter">
                        <a style="height:32px;" class="flex y-center x-left ant-dropdown-link"
                            @click="e => e.preventDefault()">
                            <span class="avatar-style">
                                <i class="med med-VIP"></i>
                                <a-avatar icon="user"
                                :src="userInfo.Avatar || require('@/assets/images/person-default.png')" />
                            </span>
                            <span class="ml10 user-name color666 flex-item fs16">{{ userInfo.RealName }}</span>
                            <a-icon class="color333 ml10" type="down" />
                        </a>
                        <a-menu slot="overlay" style="max-width:100px;">
                            <a-menu-item class="t-c">
                                <a target="_blank"
                                    :href="personal_url + committee_id">个人中心</a>
                            </a-menu-item>
                            <a-menu-item class="t-c">
                                <a target="_blank" :href="mini_url + 'user/index/' + committee_id">管理中心</a>
                            </a-menu-item>
                            <a-menu-item class="t-c">
                                <a @click="handlelogoutEvent" href="javascript:;">安全退出</a>
                            </a-menu-item>
                        </a-menu>
                    </a-dropdown>

                </div>
                <!-- <span class="mobile-menu t-c" style="max-width:50%;">摘要数据库</span> -->
                <a-icon class="mobile-menu" :class="[!(userInfo || {}).user_id ? 'mobile-menu-icon':'mobile-menu-icon1']" type="bars" :style="{ fontSize: '20px' }" @click="handleMenuList" />
            </div>
        </div>
        <!-- 专家相关 -->
        <template v-if="type == 'expert'">
            <div style="background:#1E51C9;">
                <div class="container nav-box flex x-left flex-item y-center expert-nav">
                    <router-link :to="{ path: item.path }" :class="['nav-item mr25', 'colorfff', 'fs18', { 'active colorFFA45D': index == navCurrentId }]" v-for="(item, index) in navList[0]" :key="index">
                        {{ item.name }}
                    </router-link>
                </div>
            </div>
            
        </template>
        <!-- 移动端列表icon -->
        <a-drawer class="mobile-tab" placement="left" :maskClosable='true' :closable="false" :visible="visible" @close="visible = false">
            <layout-nav @handleTitle="handleTitle($event)" :navLists="navList[0] && (navList[0].length == 6 || navList[0].length == 7) ? navList : []"></layout-nav>
        </a-drawer>
    </div>
</template>

<script>
import storage from 'store'
import search from '@/components/search';
import layoutNav from '@/components/layout/layout-nav.vue';
import { mini_url, committee_id,personal_url } from '@/config'
import { mapState } from 'vuex'
export default {
    name: 'layoutHeader',
    components: {
        search, layoutNav
    },
    props: {
        type: {
            type: String,
            default: '1' //3高级搜索 2机构号
        },
        logo: {
            type: String,
            default: 'images/logo.png'
        }, // logo地址
        companyLogo:{
            type: String,
            default: ''
        },
        placeHolderText: {
            type: String,
            default: '资讯、会议'
        }, //搜索的placeHolder
        navList: {
            type: Array,
            default: () => []
        },
        navCurrentId: {
            type: [String, Number],
            default: 0
        },
        searchStyle: {
            type: Object,
            default: () => { }
        },
        typeList: {
            type: Array,
            default: () => {
                return [
                    { id: 1, name: '会议', path: '/meeting-list' },
                    { id: 2, name: '视频', path: '/video-list' },
                    { id: 3, name: '直播', path: '/live-list' },
                    { id: 4, name: '指南', path: '/guide-list' },
                    { id: 5, name: '期刊', path: '/periodical-list' },
                    { id: 6, name: '专题', path: '/special-list' },
                ]
            }
        }
    },
    data() {
        return {
            mini_url,
            personal_url,
            committee_id,
            orgContent: {},
            search_name: this.$route.query.search_name || '', // 搜索的内容
            currSelected: undefined, //搜索下拉框默认选项
            visible: false,
            tabTitle:"",
            searchVisible: false
        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
    watch: {
        $route() {
            this.search_name = this.$route.query.search_name || '' // 搜索的内容
        }
    },
    created() {
        if (storage.get('orgLogoName')) {
            this.orgContent = JSON.parse(storage.get('orgLogoName'))
        }
        let path = this.$route.path;
        for(let i = 0,len = this.typeList.length;i < len;i++){
            let item = this.typeList[i];
             if (item.selected) {
                this.currSelected = item.path;
                 this.__search_path = item.path;
                return false;
            }
            if (item.path == path) {
                this.currSelected = path;
            }
        }
    },
    methods: {
        handleMenuList() {
            this.visible = true
        },
        handleTitle(name){
            this.visible = false
            this.tabTitle = name
        },
        // 登录
        handleLoginEvent() {
            window.location.href = this.mini_url + "unified/login?return_url=" + encodeURIComponent(window.location.href);
        },
        // 注册
        handleRegisterEvent() {
            window.location.href = this.mini_url + "unified/register?return_url=" + encodeURIComponent(window.location.href);
        },
        // 退出登录
        handlelogoutEvent() {
            storage.set('auth', '');
            storage.set('showSelected', false)
            storage.remove('subjectIds')
            storage.remove('operate_id')
            //this.$store.commit('updateState',{userInfo:null});
            window.location.href = mini_url+'site/logout/'+committee_id+'?return_url='+window.location.origin
        },
        // 下拉框回调
        handleChangeType(e) {
            this.__search_path = e;
        },
        // 搜索按钮回调
        handleSearch(search_name) {
            if (!this.typeList.length) {
                this.$emit('search', search_name || '');
                return false;
            }
            this.search_name = search_name;
            let query = JSON.parse(JSON.stringify(this.$route.query));
            if (search_name) {
                query['search_name'] = search_name
            } else {
                delete query['search_name']
            }
            this.$router.push({
                path: this.__search_path,
                query,
            })
            this.$emit('search');
        },
        // 监听清除按钮的事件
        handleChange(e) {
            // const { value } = e.target;
            // if (!value && e.type !== 'change') {
            //     this.handleSearch('');
            // }
        }
    }
}
</script>
<style lang="less" scoped>
.header {
    height: 100px;

    .container {
        height: 100%;
    }

    .login-btn {
        width: 80px;
    }
    .login-btn-new{
        width: 120px;
    }

    .vip-btn{
        padding:10px;
        border-radius: 2px;
        color:#fff;
        line-height: 1.2;
        position: fixed;
        right:10px;
        top:200px;
        margin-right:0!important;
        width:50px;
        background: linear-gradient(to bottom, #f6bd00, #b88f03);
        font-weight: bold;
        p{
            display: block;
            font-size:22px;
            font-style: italic;
            margin-bottom:0;
            transform: translateX(-5px);
            text-shadow: 0 0 5px #ffdc00;
        }
    }

    .avatar-style{
        position: relative;
        .med-VIP{
            position: absolute;
            left:0;
            bottom:-5px;
            z-index: 9;
            font-size:32px;
            height:16px;
            line-height: 16px;
            overflow: hidden;
            color:@theme;
            &:before{
                position: relative;
                z-index: 10;
            }
            &:after{
                content:'';
                position: absolute;
                left:10%;
                right: 10%;
                border-radius: 5px;
                top:10%;
                bottom:10%;
                z-index: 5;
                background-color: #fff;
            }
        }
    }


    /* 导航菜单 */
    .nav-box {
        color: @title;

        .nav-item {
            cursor: pointer;
        }

        .active {
            color: @theme;
            font-weight: 600;
        }
    }

    .search {
        /deep/.ant-input-wrapper {
            border-radius: 40px;
            border: 1px solid #d9d9d9;
            overflow: hidden;
            background: #ffffff;
        }

        /deep/.ant-btn-primary {
            background: @theme;
            width: 40px;
            padding:0;
            border-radius: 0px !important;
        }

        /deep/.ant-input {
            border: 0;
            outline: none;
            padding-left: 5px;
            padding-right:10px;
            border-radius: 20px;
            &:focus {
                box-shadow: none;
            }
        }

        .anticon-search {
            font-size: 18px;
            height: 18px;
            overflow: hidden;
        }

        /deep/ .ant-input-group-addon {
            border: none;
            background: #ffffff;
        }

        /deep/ .ant-input-affix-wrapper {
            width: 100px;
        }
    }

    .return-index {
        width: 92px;
        height: 40px;
        border: 1px solid #1E51C9;
        border-radius: 19px;
        line-height: 38px;
        color: #1E51C9;
        cursor: pointer;
    }

    .mobile-menu {
        display: none !important;
    }
    .search-icon {
        cursor: pointer !important;
        /deep/.anticon-search {
            margin-top: 0.2rem;
            font-size: 0.5rem;
        }
    }
}

@media screen and (min-width:769px){
    .search-icon {
        cursor: pointer !important;
        /deep/.anticon-search {
            font-size: 0.5rem;
        }
    }
    .h140{
        height: 155px;
        margin-bottom: 25px;
    }
    .nav{
        height: 100px!important;
    }
    .expert-nav{
        height: 55px!important;
        a:hover{
            color: #FFA45D;
        }
    }
    .colorFFA45D{
        color: #FFA45D!important;
        font-weight: 500!important;
    }
}
@media screen and (max-width:768px) {
    .search-icon {
        cursor: pointer !important;
        /deep/.anticon-search {
            margin-top: 0.1rem !important;
        }
    }
    .vip-btn{
        top:auto!important;
        bottom:150px;
    }
    .header {
        width: 100%;
        height: 50px;
        position: fixed;
        top:0;
        background: linear-gradient(-45deg, #813ae4 ,@theme);
        color: #ffffff;
        z-index: 99;
        .container {
            padding: 0 10px;
            .logo,
            .committee-logo,
            // .search-header,
            .return-index,
            .nav-box {
                display: none;
            }

            .mobile-login {
                height: 50px;
                justify-content: space-between;
                align-items: center;
                .login-btn {
                    margin-left: 0;
                    a,span,i{
                        color: #fff;
                    }
                    .med-VIP{
                        color:@theme;
                    }
                }
                .user-name{
                    flex:none;
                    max-width:45px;
                    height:20px;
                    overflow: hidden;
                }
                .anticon-down{
                    margin-left:0;
                }

                .mobile-menu {
                    display: block !important;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box !important;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                }
                .mobile-menu-icon{
                    // width: 70px;
                    text-align: right;
                    z-index: 999999;
                }
                .mobile-menu-icon1{
                    // width: 114px;
                    text-align: right;
                    z-index: 999;
                }
            }
            .search-header{
                position: absolute;
                justify-content: flex-end;
                .search{
                    width: calc(100% - 40px);
                    /deep/.ant-input-group{
                        width: 100%;
                        height: 25px!important;
                        .ant-input-group-addon{
                            border-left: 1px solid #bbb;
                        }
                        .ant-select{
                            width:70px!important;
                        }
                        .ant-input-search-button{
                            border-color: transparent;
                            background: #fff!important;
                            width: 1rem!important;
                            .anticon-search{
                                color: #aaa;
                                margin-left: -5px;
                            }
                        }
                    }
                    /deep/.ant-input-affix-wrapper{
                        width: 90%;
                    }
                    /deep/.ant-btn{
                        height: 100%;
                        line-height: 100%;
                        .anticon-search{
                            font-size: 18px;
                            margin: 0 auto;
                        }
                    }
                    /deep/.ant-input{
                        font-size: 12px;
                        height: 25px!important;
                        padding: 0;
                    }
                    /deep/.ant-input-search,/deep/.ant-select-arrow{
                        height: 25px;
                    }
                    /deep/.ant-select-selection{
                        font-size: 12px;
                    }
                }
                .search100{
                    width: calc(100% - 10px);
                    height: 25px;
                    /deep/.ant-input{
                        padding-left: 10px;
                    }
                }
                .search-custom{
                    width: calc(100% - 40px);
                }
            }
            .unlogin-header{
                right: 1.5rem;
            }
            .login-header{
                right: 1.5rem;
            }
        }
    }
    .expert-nav{
        display: none;
    }
    .mobile-tab{
        z-index: 99999999;
        top:50px;
        height:calc(100% - 50px);
        /deep/.ant-drawer-body{
            padding: 0;
        }
        /deep/.ant-drawer-content-wrapper{
            width: 200px!important;
        }
    }
}



</style>
