<template>
    <div class="nav">
       <div class="container flex y-center">
           <div style="height:100%;" class="flex x-left y-center nav-list">
                <div v-for="(item,index) in navList" :key="index" class="flex x-left flex-wrap nav-item">
                    <div v-for="(innerItem,indexs) in item" :key="indexs" :class="[index == 3 ? 'mr25 nav-item-inner':'mr25 nav-item-inner']" :style="index == 3?'width:92px;':'width:76px;'"  @click="handleTitle(innerItem.name)">
                        <!-- 外链 -->
                        <a class="nav-item-text" v-if="innerItem.path.indexOf('http') !== -1" :target="innerItem.target" :href="innerItem.path">{{innerItem.name}}</a>
                        <!-- 内部链接 -->
                        <router-link v-else :target="innerItem.target" :class="{'active':path.indexOf(innerItem.path)!=-1}" class="nav-item-text" :to="{path:innerItem.path}">{{innerItem.name}}</router-link>
                    </div>
                    <div class="__line" v-if="index!= navList.length-1"></div>
                </div>
           </div>
           <router-link class="sign" :to="{path:'/'}" v-if="navList.length > 1">
                <img src="~@/assets/images/sign-btn.png" alt="签到"/>
           </router-link>
       </div>
    </div>
</template>

<script>
export default {
    name: 'layoutNav',
    components: {
       
    },
    props:{
        navLists:{
            type:Array,
            default:()=>[]
        }
    },
    data(){
        return {
            path:this.$route.fullPath,
            navList:[
                [
                    {name:'首页',path:'/index'},
                    {name:'会议动态',path:'/infomation-list?index=115'},
                    {name:'学术资讯',path:'/infomation-list?index=116'},
                    {name:'求职招聘',path:'/infomation-list?index=3'},
                    // {name:'大咖专访',path:'/interview-list?index=2'},
                ],
                [
                    {name:'学术会议',path:'/meeting-list'},
                    {name:'期刊论文',path:'/journal-article'},
                    {name:'临床指南',path:'/guide-list'},
                    {name:'会议直播',path:'/live-list'},
                    {name:'视频回放',path:'/video-class'},
                    {name:'专题视频',path:'/special-list'}
                ],
                [
                    {name:'知名专家',path:'/expert/index'},
                    {name:'院士风采',path:'/expert/ys-mien'},
                    {name:'学科主委',path:'/expert/committee'},
                    {name:'大咖访谈',path:'/infomation-list?index=6'}
                ],
                [
                    {name:'机构号',path:'/organization/home',target:'_blank'},
                    {name:'医企秀',path:'/company/home',target:'_blank'}
                ],
                [
                    {name:'学术专题',path:'/special-ma/list'},
                    {name:'工具下载',path:'https://www.sciconf.cn/cn/download-center/512',target:'_blank'}
                ],
            ]
        }
    },
    watch:{
        $route(){
            this.path = this.$route.fullPath
        }
    },
    created() {
        if(this.navLists.length){
            this.navList = this.navLists
        }
    },
    methods:{
        handleTitle(name){
            this.$emit('handleTitle',name)
        }
    },
}
</script>
<style lang="less" scoped>
.nav{
    background-color: @theme;
    height: 98px;
    .container{
        height:100%;
    }
    .__line{
        border:1px solid rgba(255,255,255,0.15);
        height:40px;
        // margin-left:25px;
        margin-top: -20px;
    }
    .nav-item{
        margin-left:0px;
        &-text{
            display: block;
            font-size:18px;
            height:35px;
            line-height: 35px;
            text-align: justify;
            color: #FFFFFF;
            width: 100%;
            &:after{
                content:'';width: 100%;display: inline-block;overflow: hidden;height: 0;
            }
            &.active,&:hover{
               color: #FFA45D; 
            }
        }
    }
    .nav-item:last-child{
        margin-left: 15px;
    }
}
@media screen and (max-width:768px){
    .nav{
        height: 100%;
        background: #fff;
        .container{
            width: 100%;
            display: block;
            padding: 0;
            .nav-list{
                display: block;
                .nav-item{
                    display: block;
                    text-align: center;
                    &-text{
                         font-size:16px;
                    }
                    &:last-child{
                        margin-left: 0;
                    }
                    &-inner{
                        width: 100%!important;
                        margin-bottom: 5px;
                    }
                    &-text{
                        text-align: center;
                        color: #333;
                        border-bottom: 1px solid #eee;
                    }
                    .active{
                        background: @theme;
                        color: #fff;
                    }
                    .__line{
                        display: none;
                    }
                }
            }
            .sign{
                display: none;
            }
        }
    }
}
</style>