<template>
    <div class="search">
        <a-input-search :placeholder="placeholder"  size="large" :style="{width:width}"  enter-button @search="handleSearchEvent" />
    </div>
</template>

<script>
export default {
    name: 'search',
    props:{
        placeholder:{
            type:String,
            default:'search...',
        },
        width:{
            type:String,
            default:'100%'
        }
    },
    components: {
       
    },
    methods:{
        handleSearchEvent(e){
            this.$emit('search',e);
        }
    },
    mounted() {

    },
}
</script>
<style lang="less" scope>
    // 重试搜索样式
    .search{
        /deep/.ant-input-wrapper{
            border-radius:40px;
            border: 1px solid #d9d9d9;
            overflow: hidden;
        }
        /deep/ .ant-btn-primary{
            background: @theme;
            width:60px;
            border-radius:40px!important;
        }
        /deep/.ant-input{
            border:0;
            outline: none;
            padding-left:20px;
            border-radius:40px;
            &:focus{
                box-shadow: none;
            }
        }
        .anticon-search{
            font-size:22px;
            height: 18px;
            overflow: hidden;
        }
    }
</style>
